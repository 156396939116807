<template>
  <div class="home">
    <HelloWeek />
  </div>
</template>

<script>
import HelloWeek from './HelloWeek.vue'

export default {
  name: 'Home',
  components: {
    HelloWeek,
  }
}
</script>
